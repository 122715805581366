import React from "react"

const NotFoundPage = () => {
  return (
    <div>
      <p>404</p>
    </div>
  )
}

export default NotFoundPage
